<script setup lang="ts">
const { t } = useT();
const { open } = useAppModals();
const router = useRouter();
const prepareImgUrl = useImage();

const { tournamentData, activeStatus, isSubscribed, loadingSubscribtions, entriesSum, coinsSum, handleSubscribe } =
	useRace();

const benefitCardBg = `url(${prepareImgUrl("/nuxt-img/race/benefit-card-bg.webp", {
	format: "webp",
	quality: 100
})})`;

const textBtn = computed(() => {
	if (!activeStatus.value) {
		return t("More Info");
	}
	return isSubscribed.value ? t("Play now") : t("Join Now");
});

const handleSubscribeRace = () => {
	if (!activeStatus.value) {
		router.push("/races/");
		return;
	}
	handleSubscribe({
		id: tournamentData.value?.data?.id || 0,
		isSub: isSubscribed.value
	});
	open("LazyOModalRacesGames", { isRaceMode: true });
};
</script>

<template>
	<div class="race-benefit">
		<div class="content">
			<div class="wrapper">
				<AText variant="toledo" :modifiers="['medium']">
					{{ t("FunzCity Races") }}
				</AText>
				<AText variant="taipei" class="text-tlalnepantla label" :modifiers="['bold']">
					{{ t("Join & Play & Win Prizes!") }}
				</AText>
			</div>
			<div class="wrapper">
				<MPrizeFund v-if="coinsSum" variant="coins" img="/nuxt-img/prizes/funcoins@2x.png" :icon-size="24">
					<AText variant="tulsa" class="text-tlalnepantla text-count" :modifiers="['semibold', 'uppercase']">
						{{ numberFormat(coinsSum) }}
					</AText>
				</MPrizeFund>
				<MPrizeFund v-if="entriesSum" variant="entries" img="/nuxt-img/prizes/citycoins@2x.png" :icon-size="24">
					<AText variant="tulsa" class="text-tlalnepantla text-count" :modifiers="['semibold', 'uppercase']">
						{{ numberFormat(entriesSum) }}
					</AText>
				</MPrizeFund>
			</div>
			<AButton variant="primary" size="s" :modifiers="['wide']" @click="handleSubscribeRace">
				<AAnimationSpin v-if="loadingSubscribtions">
					<NuxtIcon name="24/loader" filled />
				</AAnimationSpin>
				<AText v-else class="text-tlalnepantla" :modifiers="['semibold']">{{ textBtn }}</AText>
			</AButton>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.race-benefit {
	width: 439px;
	height: 228px;
	border-radius: 16px;
	background: v-bind(benefitCardBg) no-repeat center top / cover;
	display: flex;
	justify-content: flex-end;
	padding: 16px;

	@include media-breakpoint-down(lg) {
		width: 100%;
	}

	.label {
		text-shadow: 0px 0px 6px var(--carapicuiba);
	}

	.content {
		width: 175px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		gap: 8px;
	}

	.wrapper {
		display: flex;
		flex-direction: column;
	}
}
</style>
